import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_SELECT_CONFIG, MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DATE_LOCALE, provideNativeDateAdapter } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { inOutExpandY } from '../../../shared/animations';
import { LuicModule } from '@lohmann-birkner/luic';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { CdkTextareaAutosize, TextFieldModule } from '@angular/cdk/text-field';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatGridListModule } from '@angular/material/grid-list';
import { Subscription } from 'rxjs';

dayjs.extend(utc);

export interface FormioRendererForm {
    components: any[];

    [key: string]: any;
}

export interface FormioRendererData {
    key: string;
    value: string | number | boolean | undefined;
}

/**
 * An object for the translations of the form.
 * Example:
 * ```json
 * {
 *      "de": {
 *          "Birth date": "Geburtsdatum"
 *      },
 *      "en": {
 *          "Birth date": "Birth date"
 *      }
 * }
 * ```
 */
export interface FormioRendererI18n {
    [key: string]: { [key: string]: string };
}

@Component({
    selector: 'app-formio-renderer',
    standalone: true,
    templateUrl: './formio-renderer.component.html',
    styleUrls: ['./formio-renderer.component.scss'],
    providers: [
        provideNativeDateAdapter(),
        {
            provide: MAT_DATE_LOCALE,
            useValue: 'de-DE',
        },
        {
            provide: MAT_SELECT_CONFIG,
            useValue: { overlayPanelClass: 'dropdownBorder' },
        },
    ],
    imports: [
        CommonModule,
        FormsModule,
        LuicModule,
        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatGridListModule,
        MatInputModule,
        MatRadioModule,
        MatSelectModule,
        ReactiveFormsModule,
        TextFieldModule,
    ],
    animations: [inOutExpandY],
})
export class FormioRendererComponent implements OnChanges, OnDestroy {
    @ViewChild('autosize') autosize: CdkTextareaAutosize | undefined;

    @Input() form: FormioRendererForm | undefined;
    @Input() formGroup: FormGroup = this.fb.group({});
    /** Data to populate the form's fields */
    @Input() data: FormioRendererData[] = [];

    @Output() dataChange = new EventEmitter<FormioRendererData[]>();

    @Input() i18n: FormioRendererI18n | undefined;
    @Input() readonly = false;
    @Input() maxRows = 0;

    /** Emmits the value of the "key" property of the button */
    @Output() formButtonClick = new EventEmitter<string>();
    // @ts-ignore
    @Output() formSubmitted = new EventEmitter<SubmitEvent>();

    displayNameMap = new Map([
        [Breakpoints.HandsetPortrait, 'handsetPortrait'],
        [Breakpoints.HandsetLandscape, 'handsetLandscape'],
        [Breakpoints.Web, 'web'],
        [Breakpoints.Tablet, 'tablet'],
    ]);
    currentBreakpoint: string = '';
    public components: any[] = [];
    public labels: { key: string; value: string }[] = [];

    private allSubs: Subscription[] = [];
    private updatePending = false;

    public constructor(
        private fb: FormBuilder,
        private translate: TranslateService,
        private breakpointObserver: BreakpointObserver
    ) {}

    public ngOnInit() {
        this.allSubs.push(
            this.breakpointObserver
                .observe([
                    Breakpoints.HandsetPortrait,
                    Breakpoints.HandsetLandscape,
                    Breakpoints.Web,
                    Breakpoints.Tablet,
                ])
                .subscribe((result) => {
                    for (const query of Object.keys(result.breakpoints)) {
                        if (result.breakpoints[query]) {
                            this.currentBreakpoint = this.displayNameMap.get(query) ?? '';
                        }
                    }
                })
        );
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (this.data && this.form && this.formGroup) {
            setTimeout(() => {
                this.queueUpdate();
            }, 0);
        }

        if (this.formGroup && !this.allSubs.length) {
            this.allSubs.push(
                this.formGroup.valueChanges.subscribe((fg) => {
                    const keys = Object.keys(fg);
                    const d: FormioRendererData[] = keys.map((k) => ({
                        key: k,
                        value: fg[k],
                    }));
                    this.dataChange.emit(d);
                })
            );
        }
    }

    public ngOnDestroy(): void {
        this.allSubs.forEach((sub) => sub.unsubscribe());
        this.allSubs = [];
    }

    //#region Listeners
    public onClickOnButton(key: string) {
        this.formButtonClick.emit(key);
    }

    // @ts-ignore
    public onSubmit(e?: SubmitEvent) {
        this.formSubmitted.emit(e);
    }

    public isComponentDisabled(component: any): boolean {
        // Check if the component's label is 'Admission Date'
        return component.label === 'Admission Date';
    }

    //#endregion

    public getComponentLabel(key: string) {
        return this.labels.find((e) => e.key === key)?.value;
    }

    private queueUpdate(): void {
        if (!this.updatePending) {
            this.updatePending = true;
            Promise.resolve().then(() => {
                this.refresh();
                this.updatePending = false;
            });
        }
    }

    private refresh() {
        // Aktualisiere die Komponenten aus dem Formular
        this.components = this.form?.components ?? [];
        if (!this.data || !Array.isArray(this.data)) {
            this.data = [];
        }

        // Leere die Labels, um Duplikate zu vermeiden
        this.labels = [];

        // Verarbeite alle Komponenten rekursiv
        this.components.forEach((c) => {
            this.processComponent(c);
        });

        // Initialisiere das Formular basierend auf den (rekursiv gesammelten) Komponenten
        this.initForm();
    }

    /**
     * Verarbeitet eine einzelne Komponente und deren verschachtelte Komponenten rekursiv.
     */
    private processComponent(c: any): void {
        // Finde einen extern übergebenen Daten-Eintrag für die Komponente.
        const externalData = this.data.find((e) => e.key === c.key);

        // Falls kein externer Wert existiert oder der externe Wert leer ist (aber ein Default-Wert in c['x-data'] definiert ist),
        // wird der Default-Wert übernommen und – falls noch nicht vorhanden – in this.data gespeichert.
        if (!externalData || (externalData.value === '' && c['x-data'] !== '')) {
            const defaultValue = c['x-data'];
            if (!externalData) {
                this.data.push({ key: c.key, value: defaultValue });
            }
            c['x-data'] = defaultValue;
        } else {
            c['x-data'] = externalData.value;
        }

        // Bei der Komponente "goalDateOn" soll das Datum formatiert werden (z.B. ohne Zeitzonen-Anteil)
        if (c.key === 'goalDateOn' && c['x-data']) {
            // Nutze dayjs, um den Datumswert zu parsen und im gewünschten Format ohne Zeitzone auszugeben.
            c['x-data'] = dayjs(c['x-data']).format('YYYY-MM-DDTHH:mm:ss');
        }

        // Bestimme, ob die Komponente angezeigt werden soll.
        c['x-show'] = this.showComponent(c);

        // Übersetze das Label der Komponente.
        const currentLanguage = this.translate.currentLang;
        const translationProvider = this.i18n?.[currentLanguage];
        const labelValue = translationProvider?.[c.label] || c.label;
        this.labels.push({ key: c.key, value: labelValue });

        // Übersetze Werte für selectboxes.
        if (c.type === 'selectboxes' && c.values) {
            c.values.forEach((v: any) => {
                const translatedValue =
                    this.i18n && this.i18n[currentLanguage] && this.i18n[currentLanguage][v.label]
                        ? this.i18n[currentLanguage][v.label]
                        : v.label;
                this.labels.push({ key: v.value, value: translatedValue });
            });
        }

        // Übersetze Werte für select-Komponenten.
        if (c.type === 'select' && c.data?.values) {
            c.data.values.forEach((v: any) => {
                const translatedValue =
                    this.i18n && this.i18n[currentLanguage] && this.i18n[currentLanguage][v.label]
                        ? this.i18n[currentLanguage][v.label]
                        : v.label;
                this.labels.push({ key: v.value, value: translatedValue });
            });
        }

        // Falls die Komponente verschachtelte Komponenten enthält, rekursiv verarbeiten.
        if (Array.isArray(c.components)) {
            c.components.forEach((nested: any) => this.processComponent(nested));
        }

        // Für "columns"-Komponenten: Komponenten in den einzelnen Spalten verarbeiten.
        if (c.type === 'columns' && Array.isArray(c.columns)) {
            c.columns.forEach((column: any) => {
                if (Array.isArray(column.components)) {
                    column.components.forEach((nested: any) => this.processComponent(nested));
                }
            });
        }
    }

    /**
     * Initialisiert das Formular, indem für alle unterstützten Komponenten (rekursiv)
     * entsprechende FormControls erstellt oder aktualisiert werden.
     */
    private initForm() {
        if (!this.formGroup) {
            this.formGroup = this.fb.group({});
        }

        const supportedComponentTypes = [
            'textfield',
            'number',
            'password',
            'textarea',
            'checkbox',
            'select',
            'radio',
            'datetime',
            'dateandtime',
            'fieldset',
            'selectboxes',
        ];

        // Hole alle (auch verschachtelte) Komponenten als flache Liste.
        const allComponents = this.getAllComponents(this.components);

        allComponents.forEach((c) => {
            if (supportedComponentTypes.includes(c.type)) {
                const controlExists = this.formGroup.contains(c.key);
                // Verwende den in der Komponente gesetzten x-data-Wert, ohne auf einen leeren String zurückzufallen.
                const controlValue = c['x-data'];
                if (!controlExists) {
                    const fc = new FormControl(controlValue);
                    if (c.disabled || this.readonly) {
                        fc.disable({ onlySelf: true });
                    }
                    this.formGroup.addControl(c.key, fc);
                } else {
                    const currentControl = this.formGroup.get(c.key);
                    if (currentControl && currentControl.value !== controlValue) {
                        if (c.type === 'datetime' || c.type === 'dateandtime') {
                            currentControl.setValue(controlValue ? controlValue.substr(0, 19) : controlValue, {
                                emitEvent: false,
                            });
                        } else {
                            currentControl.setValue(controlValue, { emitEvent: false });
                        }
                    }
                }
            }
        });
    }

    /**
     * Extrahiert rekursiv alle Komponenten aus der gegebenen Komponenten-Liste,
     * inklusive verschachtelter Komponenten (z.B. in Columns oder Fieldsets).
     */
    private getAllComponents(components: any[]): any[] {
        let all: any[] = [];
        components.forEach((c) => {
            all.push(c);
            if (Array.isArray(c.components)) {
                all = all.concat(this.getAllComponents(c.components));
            }
            if (c.type === 'columns' && Array.isArray(c.columns)) {
                c.columns.forEach((column: any) => {
                    if (Array.isArray(column.components)) {
                        all = all.concat(this.getAllComponents(column.components));
                    }
                });
            }
        });
        return all;
    }

    private showComponent(component: any): boolean {
        if (component.hidden) return false;

        const cc = component.conditional;
        if (cc) {
            if (cc.conditions?.length) {
                // Neue Form.io-Version: Aggregiere alle Bedingungen (AND-Logik)
                const conditionsMet = cc.conditions.every((condition: any) => {
                    const conditionData = this.data.find((e) => e.key === condition.component)?.value;
                    switch (condition.operator) {
                        case 'isNotEmpty':
                            return !!conditionData;
                        case 'isEmpty':
                            return !conditionData;
                        case 'isEqual':
                            return conditionData === condition.value;
                        case 'isNotEqual':
                            return conditionData !== condition.value;
                        default:
                            return false;
                    }
                });
                return conditionsMet ? cc.show : !cc.show;
            } else if (cc.when) {
                // Alte Form.io-Version: Einzelne Bedingung
                const conditionField = this.data.find((e) => e.key === cc.when);
                const conditionData = conditionField?.value;
                return conditionData === cc.eq ? cc.show : !cc.show;
            }
        }

        return true;
    }
}
